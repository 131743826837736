import { gql, useMutation } from "@apollo/client";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faNewspaper,
  faThumbtack,
  faBookmark,
  faLevelDownAlt,
  faCheck,
} from "@fortawesome/free-solid-svg-icons";
import { head, groupBy, unionBy, values, orderBy, isEmpty, noop, filter, size } from "lodash";
import React, { useEffect, useState } from "react";
import Loader from "./loader";
import { GET_USER } from "../pages/app/library";

export const defaultStatuses = [
  { id: 1, name: "Want to read", count: 0 },
  { id: 2, name: "Currently reading", count: 0 },
  { id: 3, name: "Dropped", count: 0 },
  { id: 4, name: "Read", count: 0 },
];

const UPDATE_USER_ARTICLE_STATUS = gql`
  mutation UpdateUserArticleStatus($id: Int!, $status_id: Int!) {
    update_user_articles(
      _set: { status_id: $status_id }
      where: { id: { _eq: $id } }
    ) {
      returning {
        id
        status {
          id
          name
        }
      }
    }
  }
`;

const INSERT_FEED_EVENT = gql`
  mutation InsertFeedEvent($user_article_id: Int!, $status_id: Int!) {
    insert_feed_events_one(
      object: { user_article_id: $user_article_id, status_id: $status_id }
    ) {
      id
    }
  }
`;

function getIcon(name) {
  if (name === "All articles") {
    return faNewspaper;
  }

  if (name === "Want to read") {
    return faThumbtack;
  }

  if (name === "Currently reading") {
    return faBookmark;
  }

  if (name === "Dropped") {
    return faLevelDownAlt;
  }

  if (name === "Read") {
    return faCheck;
  }
}

function Menu({
  className,
  onChange = noop,
  onChanging = noop,
  userArticleId,
  userId,
  statusName,
}) {
  const [updateUserArticleStatus, { data }] = useMutation(
    UPDATE_USER_ARTICLE_STATUS,
    {
      refetchQueries: [
        {
          query: GET_USER,
          variables: { id: userId },
        },
      ],
    }
  );
  const [insertFeedEvent] = useMutation(INSERT_FEED_EVENT);

  useEffect(() => {
    if (!isEmpty(data)) {
      const userArticle = head(data?.update_user_articles?.returning);
      onChange(userArticle?.status?.name);
      insertFeedEvent({
        variables: {
          user_article_id: userArticle?.id,
          status_id: userArticle?.status?.id,
        },
      });
    }
  }, [data]);

  return (
    <div
      className={`${className} w-max self-center flex flex-col p-2 rounded-md border-solid border-2 border-black dark:border-white`}
    >
      {filter(defaultStatuses, ({ name }) => name !== statusName).map(({ id, name }) => (
        <div
          key={id}
          className="cursor-pointer hover:bg-gray-200 dark:hover:text-black"
          onClick={() => {
            updateUserArticleStatus({
              variables: {
                id: userArticleId,
                status_id: id,
              },
            });
            onChanging();
          }}
        >
          <FontAwesomeIcon className="mr-2" icon={getIcon(name)} />
          {name}
        </div>
      ))}
    </div>
  );
}

export function Status({
  className,
  isActive,
  name: defaultName,
  count,
  onClick,
  isReadOnly,
  userId,
  userArticleId,
}) {
  const [isShowingMenu, setIsShowingMenu] = useState(false);
  const [name, setName] = useState("");
  const [isChanging, setIsChanging] = useState(false);

  useEffect(() => {
    setName(defaultName);
  }, [defaultName]);

  return (
    <>
      <div
        className={`${className} flex flex-row transition-all duration-75 delay-75 ease-out items-center justify-between rounded-sm mb-1 p-1 ${
          isActive || isReadOnly ? "bg-gray-100 text-black" : ""
        } dark:hover:text-black hover:bg-gray-200 cursor-pointer`}
        onClick={isReadOnly ? () => setIsShowingMenu(!isShowingMenu) : onClick}
      >
        <div className="flex items-center">
          {isChanging && <Loader />}
          {!isChanging && (
            <>
              <FontAwesomeIcon className="mr-2" icon={getIcon(name)} />
              <div>{name}</div>
            </>
          )}
        </div>
        {count && <div>{count}</div>}
      </div>
      {isShowingMenu && (
        <Menu
          className={isChanging ? "hidden" : ""}
          onChange={(newName) => {
            setIsChanging(false);
            setIsShowingMenu(false);
            setName(newName);
          }}
          onChanging={() => {
            setIsChanging(true);
          }}
          userId={userId}
          userArticleId={userArticleId}
          statusName={name}
        />
      )}
    </>
  );
}

export default function Statuses({
  activeStatus,
  className,
  onClick,
  statuses,
}) {
  const groupedStatuses = values(groupBy(statuses, "id"));
  const aggregateStatuses = [
    { id: 0, name: "All articles", count: size(statuses) },
    ...orderBy(
      unionBy(
        groupedStatuses.map((statusGroup) => ({
          ...head(statusGroup),
          count: statusGroup.length,
        })),
        defaultStatuses,
        "name"
      ),
      "id"
    ),
  ];
  return (
    <div className={`${className} flex flex-col`}>
      {aggregateStatuses.map((status) => (
        <Status
          key={status.id}
          {...status}
          isActive={activeStatus === status.id}
          onClick={() => onClick(status.id)}
        />
      ))}
    </div>
  );
}
