import { gql, useQuery, useMutation, useLazyQuery } from '@apollo/client';
import {
  faAngleDoubleRight,
  faBomb,
  faBroom,
  faCheck,
  faClone,
  faExclamationCircle,
  faHammer,
  faHatWizard,
  faPencilAlt,
  faPlus,
  faTags,
  faTimes,
  faUndo,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { navigate } from '@reach/router';
import { convert } from 'html-to-text';
import {
  get,
  head,
  size,
  isEmpty,
  filter,
  partial,
  noop,
  lowerCase,
  find,
  mapValues,
} from 'lodash';
import queryString from 'query-string';
import React, { useEffect, useRef, useState } from 'react';
import { useClickAway } from 'react-use';

import Badge from '../../components/badge';
import Button from '../../components/button';
import Heading from '../../components/heading';
import Layout from '../../components/layout';
import Loader from '../../components/loader';
import TextInput from '../../components/text-input';
import { state } from '../../components/provider';
import Statuses, { Status } from '../../components/statuses';
import getDefaultImage from '../../functions/getDefaultImage';
import { getProfile } from '../../services/auth';

async function getUrlMetadata(baseUrl) {
  const url = `https://material-superficial-tulip.glitch.me?url=${baseUrl}`;
  const response = await fetch(url, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
    },
  });
  return await response.json();
}

export const GET_USER = gql`
  query GetUser($id: Int) {
    users(where: { id: { _eq: $id } }) {
      id
      avatar
      name
      email
      username
      shelves {
        id
        name
        user_articles {
          id
          article {
            author
            id
            title
            url
            cover
            description
          }
          status {
            id
            name
          }
        }
      }
      subscriptions {
        id
      }
      user_articles {
        id
        article {
          author
          id
          title
          url
          cover
          description
        }
        shelf {
          id
          name
        }
        status {
          id
          name
        }
      }
      following_aggregate {
        aggregate {
          count
        }
      }
      followers_aggregate {
        aggregate {
          count
        }
      }
    }
  }
`;

const INSERT_ARTICLE = gql`
  mutation InsertArticle(
    $url: String!
    $title: String!
    $cover: String!
    $author: String!
    $description: String!
  ) {
    insert_articles_one(
      object: {
        author: $author
        cover: $cover
        title: $title
        url: $url
        description: $description
      }
      on_conflict: {
        constraint: articles_url_key
        update_columns: [updated_at]
      }
    ) {
      id
      author
    }
  }
`;

const INSERT_USER_ARTICLE = gql`
  mutation InsertUserArticle(
    $article_id: Int!
    $user_id: Int!
    $status_id: Int!
    $shelf_id: Int!
  ) {
    insert_user_articles_one(
      object: {
        article_id: $article_id
        user_id: $user_id
        status_id: $status_id
        shelf_id: $shelf_id
      }
    ) {
      id
      status {
        id
      }
    }
  }
`;

const INSERT_FEED_EVENT = gql`
  mutation InsertFeedEvent($user_article_id: Int!, $status_id: Int!) {
    insert_feed_events_one(
      object: { user_article_id: $user_article_id, status_id: $status_id }
    ) {
      id
    }
  }
`;

const INSERT_SHELF = gql`
  mutation InsertShelf($user_id: Int!, $name: String!) {
    insert_shelves_one(object: { name: $name, user_id: $user_id }) {
      id
      name
    }
  }
`;

const UPDATE_USER_ARTICLE_SHELF = gql`
  mutation UpdateUserArticleShelf($id: Int!, $shelf_id: Int) {
    update_user_articles(
      _set: { shelf_id: $shelf_id }
      where: { id: { _eq: $id } }
    ) {
      returning {
        id
        shelf {
          id
          name
        }
      }
    }
  }
`;

const CLONE_SHELF = gql`
  mutation InsertShelf(
    $user_id: Int!
    $name: String!
    $data: [user_articles_insert_input!] = {}
  ) {
    insert_shelves_one(
      object: { name: $name, user_id: $user_id, user_articles: { data: $data } }
    ) {
      id
      name
    }
  }
`;

const UPDATE_SHELF_NAME = gql`
  mutation UpdateShelfName($id: Int!, $name: String!) {
    update_shelves(where: {id: {_eq: $id}}, _set: {name: $name}) {
      returning {
        id
        name
      }
    }
  }
`;

function getCount(aggregate) {
  return get(aggregate, 'aggregate.count');
}

function normalizeUser({
  following_aggregate,
  followers_aggregate,
  user_articles,
  ...rest
}) {
  return {
    ...rest,
    followers: getCount(followers_aggregate),
    following: getCount(following_aggregate),
    readCount: user_articles
      .map(({ status }) => status)
      .filter(({ id }) => id === 4).length,
    user_articles,
  };
}

function isThird(value) {
  return value % 3 === 0;
}

function AddArticleDialog({ onClose, userId, activeShelf }) {
  const ref = useRef(null);
  useClickAway(ref, () => {
    onClose();
  });

  const [success, setSuccess] = useState('');

  const [insertArticle, { data, error: insertArticleError }] =
    useMutation(INSERT_ARTICLE);
  const [insertUserArticle] = useMutation(INSERT_USER_ARTICLE, {
    onCompleted: ({ insert_user_articles_one: { id, status } }) => {
      setSuccess('Article was added to your library.');
      insertFeedEvent({
        variables: {
          user_article_id: id,
          status_id: status.id,
        },
      });
    },
  });
  const [insertFeedEvent] = useMutation(INSERT_FEED_EVENT, {
    refetchQueries: [
      {
        query: GET_USER,
        variables: {
          id: userId,
        },
      },
    ],
  });

  const [url, setUrl] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [error, setError] = useState('');

  useEffect(() => {
    if (!isEmpty(data)) {
      insertUserArticle({
        variables: {
          article_id: data?.insert_articles_one?.id,
          status_id: 1,
          user_id: userId,
          shelf_id: !!activeShelf ? activeShelf : null,
        },
      });
    }
  }, [data, setSuccess]);

  async function handleSubmit(value) {
    setIsSubmitting(true);

    try {
      const resp = await getUrlMetadata(url || value);
      const result = mapValues(resp, (x) => convert(x));
      console.log({ resp, result })
      insertArticle({
        variables: {
          author: result.author || result.source || '',
          cover: result.image || '',
          title: result.title || '',
          url: result.url || url || value || '',
          description: result.description || '',
        },
      });
    } catch (error) {
      setError(
        error?.message ||
          'Whoops! Something went wrong processing this article. Please try again.'
      );
    } finally {
      setIsSubmitting(false);
    }
  }

  if (isSubmitting) {
    return (
      <div className="flex items-center">
        <Loader />
        <div className="text-sm ml-2">
          Hang tight, it takes time to process...
        </div>
      </div>
    );
  }

  return (
    <div className="flex flex-col" ref={ref}>
      {(!!error || !isEmpty(insertArticleError)) && (
        <div className="w-full mb-4 bg-red-600 text-white py-2 px-4 rounded-md">
          <FontAwesomeIcon className="mr-2" icon={faExclamationCircle} />
          {error || insertArticleError?.message}
        </div>
      )}
      {!!success && (
        <div className="w-full mb-4 bg-green-600 text-white py-2 px-4 rounded-md">
          <FontAwesomeIcon className="mr-2" icon={faCheck} />
          Article was added to your library.
        </div>
      )}
      <label className="flex flex-row text-sm mb-1 w-full justify-between">
        Paste URL
        {!isEmpty(url) && (
          <div className="opacity-75 text-sm" onClick={() => setUrl('')}>
            Clear
          </div>
        )}
      </label>
      <input
        className="text-black px-3 py-1 rounded-md outline-none border-solid border-2 border-black dark:border-white"
        onChange={event => {
          setUrl(event.target.value);
        }}
        onKeyPress={event => {
          if (event.which !== 13) {
            return null;
          }

          handleSubmit();
        }}
        onPaste={event => {
          setUrl(event.clipboardData.getData('Text'));
          handleSubmit(event.clipboardData.getData('Text'));
        }}
        placeholder="https://example.com/article"
        type="text"
        value={url}
      />
    </div>
  );
}

function AddShelfDialog({ onClose, userId }) {
  const ref = useRef(null);
  useClickAway(ref, () => {
    onClose();
  });

  const [shelfName, setShelfName] = useState('');

  const [insertShelf, { loading }] = useMutation(INSERT_SHELF, {
    onCompleted: () => {
      onClose();
    },
    refetchQueries: [
      {
        query: GET_USER,
        variables: {
          id: userId,
        },
      },
    ],
  });

  function handleSubmit() {
    insertShelf({
      variables: {
        name: shelfName,
        user_id: userId,
      },
    });

    return null;
  }

  if (loading) {
    return (
      <div className="flex items-center">
        <Loader />
        <div className="text-sm ml-2">Building...</div>
      </div>
    );
  }

  return (
    <div className="flex flex-col" ref={ref}>
      <label className="flex flex-row text-sm mb-1 w-full justify-between">
        Name your shelf
        {!isEmpty(shelfName) && (
          <div className="opacity-75 text-sm" onClick={() => setShelfName('')}>
            Clear
          </div>
        )}
      </label>
      <input
        className="px-3 py-1 rounded-md text-black outline-none border-solid border-2 border-black dark:border-white"
        onChange={event => {
          setShelfName(event.target.value);
        }}
        onKeyPress={event => {
          if (event.which !== 13) {
            return null;
          }

          handleSubmit();
        }}
        placeholder="College Football"
        type="text"
        value={shelfName}
      />
    </div>
  );
}

function Profile({
  avatar,
  followers,
  following,
  name,
  username,
  readCount,
  subscriptions,
  isViewingOtherProfile,
}) {
  return (
    <div className="w-96 flex flex-col self-center items-center my-8 transition-all duration-75 delay-75 ease-out">
      <div
        onClick={() => {
          if (!isViewingOtherProfile) {
            navigate('/app/edit-profile');
            return null;
          }
        }}
        className="w-32 cursor-pointer relative border-2 border-solid border-black dark:border-white shadow-sm h-32 rounded-full"
      >
        <img
          alt="avatar"
          className="w-full object-cover object-center h-full rounded-full"
          src={avatar || getDefaultImage({ name })}
        />
      </div>
      <div className="flex items-center mt-4 text-4xl">
        {name}
        <Badge className="ml-2" subscriptions={subscriptions} />
      </div>
      <div className="text-lg text-gray-900 dark:text-white">@{username}</div>
      <div className="flex justify-between w-full mt-4">
        <div className="flex flex-col items-center justify-center w-1/3">
          <div className="font-bold">Articles Read</div>
          <div>{readCount}</div>
        </div>
        <div className="flex flex-col items-center justify-center w-1/3">
          <div className="font-bold">Followers</div>
          <div>{followers}</div>
        </div>
        <div className="flex flex-col items-center justify-center w-1/3">
          <div className="font-bold">Following</div>
          <div>{following}</div>
        </div>
      </div>
    </div>
  );
}

function ShelfMenu({
  className,
  onChange = noop,
  onChanging = noop,
  shelf,
  shelves,
  userId,
  userArticleId,
}) {
  const [updateUserArticleShelf] = useMutation(UPDATE_USER_ARTICLE_SHELF, {
    onCompleted: () => {
      onChange();
    },
    refetchQueries: [
      {
        query: GET_USER,
        variables: { id: userId },
      },
    ],
  });

  function handleSubmit(shelf_id) {
    onChanging();
    updateUserArticleShelf({
      variables: {
        id: userArticleId,
        shelf_id: shelf_id ? shelf_id : null,
      },
    });
  }

  const options = !isEmpty(shelf)
    ? [
        ...shelves,
        {
          id: 0,
          name: 'Remove from Shelf',
        },
      ]
    : shelves;

  return (
    <div
      className={`${className} w-max self-center flex flex-col p-2 rounded-md border-solid border-2 border-black dark:border-white`}
    >
      {filter(options, ({ id }) => id !== shelf.id).map(({ id, name }) => (
        <div
          key={id}
          className="cursor-pointer hover:bg-gray-200 dark:hover:text-black"
          onClick={() => {
            handleSubmit(id);
          }}
        >
          <FontAwesomeIcon className="mr-2" icon={id === 0 ? faUndo : faTags} />
          {name}
        </div>
      ))}
    </div>
  );
}

function Card({
  cover,
  disabled,
  index,
  title,
  author,
  status,
  url,
  description,
  id,
  userId,
  shelf,
  shelves,
  totalArticles,
}) {
  const [isShelfMenuOpen, setIsShelfMenuOpen] = useState(false);
  const [isShelfSubmitting, setIsShelfSubmitting] = useState(false);
  return (
    <div
      className={`${
        index === 0
          ? 'items-center w-full border-t-2 border-b-0 border-r-2 border-l-2'
          : `${
              isThird(index + 1)
                ? index + 1 === totalArticles
                  ? 'w-1/2 md:w-2/4 border-r-2 md:border-r-2 border-l-0'
                  : 'w-1/2 md:w-2/4 border-r-2 md:border-r-0 border-l-0'
                : 'w-1/2 md:w-1/4 border-l-2 border-r-2'
            }`
      } break-words flex flex-col text-center p-2 border-b-2 border-solid border-black dark:border-white transition-all duration-75 delay-75 ease-out`}
    >
      <a
        className={`${index === 0 ? 'text-xl md:text-4xl' : ''}
        ${isThird(index + 1) ? 'text-lg md:text-2xl' : 'text-base md:text-xl'}
        font-bold hover:underline mb-1`}
        href={url}
        target="_blank"
        rel="noopener noreferrer"
      >
        {title}
      </a>
      <div className="mb-2 text-center">{author}</div>
      {(index === 0 || isThird(index + 1)) && (
        <img
          alt="cover"
          className="my-2 w-full h-auto filter grayscale"
          src={
            cover ||
            'https://images.unsplash.com/photo-1532210317995-cc56d90177d9?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1050&q=80'
          }
        />
      )}
      <div className="my-4 px-6 md:px-8 text-left">{description}</div>
      {!disabled && (
        <Status
          className="my-4 w-max self-center"
          isReadOnly
          name={status.name}
          userId={userId}
          userArticleId={id}
        />
      )}
      {!disabled && !isEmpty(shelves) && !isShelfSubmitting && (
        <div
          className="w-max self-center flex flex-row transition-all duration-75 delay-75 ease-out items-center justify-between rounded-sm my-1 p-1 cursor-pointer bg-gray-100 hover:bg-gray-200 text-black"
          onClick={() => {
            setIsShelfMenuOpen(!isShelfMenuOpen);
          }}
        >
          <FontAwesomeIcon
            className="mr-2"
            icon={shelf.name ? faTags : faAngleDoubleRight}
          />
          {shelf.name || 'Move to Shelf'}
        </div>
      )}
      {isShelfMenuOpen && (
        <ShelfMenu
          className={isShelfSubmitting ? 'hidden' : ''}
          onChange={() => {
            setIsShelfSubmitting(false);
            setIsShelfMenuOpen(false);
          }}
          onChanging={() => {
            setIsShelfSubmitting(true);
          }}
          shelf={shelf}
          shelves={shelves}
          userId={userId}
          userArticleId={id}
        />
      )}
      {isShelfSubmitting && (
        <div className="w-max self-center flex flex-row transition-all duration-75 delay-75 ease-out items-center justify-between rounded-sm my-1 p-1 cursor-pointer bg-gray-100 hover:bg-gray-200 text-black">
          <Loader />
        </div>
      )}
    </div>
  );
}

function filterByShelf(activeShelf, { shelf }) {
  return !activeShelf ? true : shelf?.id === activeShelf;
}

function filterByStatus(activeStatus, { status }) {
  return activeStatus === 0 ? true : status.id === activeStatus;
}

export default function Library() {
  const [isAddingArticle, setIsAddingArticle] = useState(false);
  const [activeStatus, setActiveStatus] = useState(0);
  const [activeShelf, setActiveShelf] = useState(0);
  const [isAddingShelf, setIsAddingShelf] = useState(false);
  const [isNamingShelf, setIsNamingShelf] = useState(false);
  const [shelfName, setShelfName] = useState('');
  const [hasClonedShelf, setHasClonedShelf] = useState({});

  const isBrowser = typeof window !== 'undefined';
  const { id: userId } = queryString.parse(
    isBrowser ? window.location?.search : ''
  );
  const authenticatedUser = getProfile();
  const currentUserId = userId ? userId : authenticatedUser.id;

  const { data, loading, error } = useQuery(GET_USER, {
    variables: {
      id: currentUserId,
    },
  });

  const user = isEmpty(data) ? {} : normalizeUser(head(data.users));
  const shelves = isEmpty(user) ? [] : user.shelves;
  const shelf = find(shelves, { id: activeShelf });
  const articles = filter(
    filter(
      user.user_articles,
      partial(filterByShelf, activeShelf)
    ),
    partial(filterByStatus, activeStatus)
  );

  const statuses = isEmpty(user)
    ? []
    : !isEmpty(shelves) && activeShelf
    ? shelves.flatMap(({ user_articles }) =>
        user_articles.map(({ status }) => status)
      )
    : user.user_articles.map(({ status }) => status);

  const isViewingOtherProfile = !!userId;
  const hasClassy =
    (isViewingOtherProfile && !isEmpty(authenticatedUser.subscriptions)) ||
    (!isViewingOtherProfile && !isEmpty(user.subscriptions));

  function handleBuildShelf({ toggleClassyModal: openClassyModal }) {
    if (!hasClassy) {
      openClassyModal();
      return null;
    }

    setIsAddingShelf(true);
    return null;
  }

  const [cloneShelf, { loading: isCloningShelf }] = useMutation(CLONE_SHELF);

  const [renameShelf, { loading: isProcessingRenamingShelf }] = useMutation(UPDATE_SHELF_NAME, {
    refetchQueries: [
      {
        query: GET_USER,
        id: authenticatedUser.id,
      },
    ],
  })

  function handleRenameShelf() {
    if (!shelfName) {
      return null;
    }

    renameShelf({
      variables: {
        id: activeShelf,
        name: shelfName,
      },
    });

    setIsNamingShelf(false);
  }

  return (
    <Layout title="Library">
      <state.Consumer>
        {context => (
          <>
            <div className="flex flex-col w-full md:w-1/4 pr-4">
              <Heading>Library</Heading>
              {loading && <loader />}
              {data && !isEmpty(user) && (
                <Statuses
                  activeStatus={activeStatus}
                  statuses={statuses}
                  onClick={setActiveStatus}
                />
              )}
              {error && (
                <div className="flex">
                  <FontAwesomeIcon className="mr-2" icon={faBomb} />
                  <div>Something is blowing up. Please try again later.</div>
                </div>
              )}
              <Heading className="mt-8">Shelves</Heading>
              {loading && <Loader />}
              {data && !isEmpty(user) && (
                <>
                  {isEmpty(shelves) &&
                  (!hasClassy || !isViewingOtherProfile) ? (
                    <div className="-mt-4 mb-4">
                      Build shelves to categorize your articles.
                    </div>
                  ) : (
                    <div className="flex flex-col mb-8">
                      {!!activeShelf && (
                        <div
                          className="flex flex-row items-center -mt-4 mb-4 cursor-pointer"
                          onClick={() => {
                            setActiveShelf(null);
                          }}
                        >
                          {!isProcessingRenamingShelf && <FontAwesomeIcon className="mr-2" icon={faTimes} />}
                          {isProcessingRenamingShelf ? <Loader /> : lowerCase(shelfName) || lowerCase(
                            shelves.find(({ id }) => id === activeShelf).name
                          )}
                        </div>
                      )}
                      {shelves.map(shelf => (
                        <div
                          className={`flex justify-between items-center transition-all duration-75 delay-75 ease-out dark:hover:text-black mb-2 px-2 py-1 cursor-pointer hover:bg-gray-200 ${
                            activeShelf === shelf.id
                              ? 'bg-gray-100 dark:text-black'
                              : 'dark:text-white'
                          }`}
                          key={shelf.id}
                          onClick={() => {
                            setActiveShelf(shelf.id);
                          }}
                        >
                          <div className="flex items-center">
                            {shelf.id === activeShelf && !isProcessingRenamingShelf && (
                              <FontAwesomeIcon
                                className="mr-2"
                                icon={isNamingShelf ? faTimes : faPencilAlt}
                                onClick={event => {
                                  event.stopPropagation();
                                  if (isNamingShelf) {
                                    setShelfName('');
                                  } else {
                                    setShelfName(shelf.name);
                                  }
                                  setIsNamingShelf(!isNamingShelf);
                                }}
                              />
                            )}
                            {isNamingShelf ? (
                              <TextInput
                                className="mt-2"
                                onChange={event => {
                                  setShelfName(event.target.value);
                                }}
                                onKeyPress={event => {
                                  if (event.which !== 13) {
                                    return null;
                                  }

                                  handleRenameShelf();
                                }}
                                value={shelfName}
                              />
                            ) : isProcessingRenamingShelf ? <Loader /> : (
                              shelf.name
                            )}
                          </div>
                          <div>{shelf.count || size(shelf.user_articles)}</div>
                        </div>
                      ))}
                    </div>
                  )}
                  {(!hasClassy || !isViewingOtherProfile) && !isAddingShelf && (
                    <Button
                      className="flex items-center w-max"
                      onClick={() => {
                        handleBuildShelf(context);
                      }}
                    >
                      <FontAwesomeIcon
                        className={`mr-2 ${
                          !hasClassy ? 'text-yellow-400' : ''
                        }`}
                        icon={faHammer}
                      />
                      Build a Shelf
                    </Button>
                  )}
                  {isAddingShelf && (
                    <AddShelfDialog
                      onClose={() => {
                        setIsAddingShelf(false);
                      }}
                      userId={currentUserId}
                    />
                  )}
                </>
              )}
            </div>
            <div className="flex flex-col w-full md:w-3/4 pl-4">
              {loading && (
                <div className="flex justify-center min-h-screen">
                  <Loader className="my-8" />
                </div>
              )}
              {data && !isEmpty(user) && (
                <>
                  <Profile
                    isViewingOtherProfile={isViewingOtherProfile}
                    {...user}
                  />
                  {isViewingOtherProfile && !!activeShelf && hasClassy && !isEmpty(shelf?.user_articles) && (
                    <div className="flex flex-row justify-end w-full">
                      <Button
                        onClick={() => {
                          cloneShelf({
                            variables: {
                              user_id: authenticatedUser.id,
                              name: shelf.name,
                              data: shelf.user_articles,
                            },
                            onCompleted: () => {
                              setHasClonedShelf({
                                ...hasClonedShelf,
                                [activeShelf]: true,
                              });
                            },
                            refetchQueries: [
                              {
                                query: GET_USER,
                                id: authenticatedUser.id,
                              },
                            ],
                          });
                        }}
                      >
                        {isCloningShelf ? (
                          <Loader />
                        ) : hasClonedShelf[activeShelf] ? (
                          <div className="w-full mb-4 bg-green-600 text-white py-2 px-4 rounded-md">
                            <FontAwesomeIcon className="mr-2" icon={faCheck} />
                            Shelf was cloned
                          </div>
                        ) :  (
                          <>
                            <FontAwesomeIcon className="mr-2" icon={faClone} />
                            Clone Shelf
                          </>
                        )}
                      </Button>
                    </div>
                  )}
                  {!isViewingOtherProfile && (
                    <div className="flex flex-row justify-end w-full">
                      {!isAddingArticle && (
                        <Button onClick={() => setIsAddingArticle(true)}>
                          <FontAwesomeIcon className="mr-2" icon={faPlus} />
                          Add article
                        </Button>
                      )}
                      {isAddingArticle && (
                        <AddArticleDialog
                          activeShelf={activeShelf}
                          onClose={() => setIsAddingArticle(false)}
                          userId={user.id}
                        />
                      )}
                    </div>
                  )}
                  {isEmpty(user.user_articles) && isViewingOtherProfile && (
                    <div className="p-4 flex flex-col items-center justify-center flex-wrap w-full mt-8 h-64 border-dotted border-black border-2">
                      <FontAwesomeIcon
                        className="mb-4 text-4xl"
                        icon={faBroom}
                      />
                      <div className="text-lg">
                        {user.name} must be busy sweeping. There are no
                        articles.
                      </div>
                    </div>
                  )}
                  {isEmpty(user.user_articles) && !isViewingOtherProfile && (
                    <div className="p-4 flex flex-col items-center justify-center flex-wrap w-full mt-8 h-64 border-dotted border-black dark:border-white border-2">
                      <FontAwesomeIcon
                        className="mb-4 text-4xl"
                        icon={faHatWizard}
                      />
                      <div className="text-lg">
                        Hey, {user.name}! Click "Add article" to start. It's
                        magic.
                      </div>
                    </div>
                  )}
                  {!isEmpty(user.user_articles) && (
                    <div className="flex flex-row flex-wrap w-full mt-8">
                      {articles.map((userArticle, index) => (
                        <Card
                          key={userArticle.id}
                          {...userArticle.article}
                          disabled={isViewingOtherProfile}
                          id={userArticle.id}
                          index={index}
                          status={userArticle.status}
                          userId={user.id}
                          shelf={userArticle.shelf || {}}
                          shelves={shelves || []}
                          totalArticles={size(articles)}
                        />
                      ))}
                    </div>
                  )}
                </>
              )}
            </div>
          </>
        )}
      </state.Consumer>
    </Layout>
  );
}
